<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3 class="color-3">Packages</h3>
					</div>


					<div class="shadow rounded p-3">
						
						<div class="table-responsive">

							<table class="table table-hover">
								<thead>
									<tr> <th> Package name </th> <th>Prefix</th> <th> Cost </th> <th>Description</th> <th>Actions</th> </tr>
								</thead>

								<tbody>
									
									<tr v-for="p in packages">
										
										<td> <router-link :to=" { name : 'package-details', params : { id : p.id } } " > {{p.name}} </router-link>  </td>
										<td> {{p.prefix}}  </td>
										<td> Ksh.{{p.cost}}  </td>
										<td> {{p.description}}  </td>
										<td> <router-link :to=" { name : 'package-details', params : { id : p.id } } " class="btn btn-outline-success"> <i class="bi bi-eye"></i> View  </router-link> </td>

									</tr>

								</tbody>
								
							</table>
							
						</div>

					</div>

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			
			packages : [],	
			
			}
		},

		methods : {
			async get_packages(){

				const res = await axios.get(this.$store.state.url+'api/get-packages').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.packages = res 	
			}
		
		},

		created(){
			this.get_packages()
		}

	}

</script>