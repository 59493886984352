<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3 class="color-3">New resource</h3>
					</div>


					<div class="shadow rounded p-3">
						
						<form @submit.prevent="new_resource()" enctype="multipart/form-data">
						
						<div class="row">
						

							<div class="col-md-6 mt-2">
								<label>Name</label>
								<input  type="text" v-model="name" class="form-control">
							</div>

							<div class="col-md-6 mt-2">
								<label>File</label>
								<input  type="file" @change="get_file" class="form-control">
							</div>

							<div class="col-md-12 mt-3">
								
								<button class="btn btn-1" :disabled="disabled"> {{text}} </button>
							</div>

						</div>

					</form>

					</div>

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			
			text : 'Submit',
			disabled : false,
			name : '',
			file : ''	
			}
		},

		methods : {

			get_file(event){
				this.file = event.target.files[0]
				
			},


			async new_resource(){


				if(!this.name){

				Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Name cannot be blank',
					  showConfirmButton: true
					  
					})
				return
				}



				if(!this.file){

				Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'File cannot be blank',
					  showConfirmButton: true
					  
					})
				return
				}


				this.text = 'Please wait...'
				this.disabled = true

					 const config = {
      header : {
        'Content-Type' : 'multipart/form-data'
      }
    }

    			let form_data = new FormData()
				form_data.append('name',this.name)
				form_data.append('file',this.file)

				const res = await axios.post(this.$store.state.url+'api/new-resource',form_data,config).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				Swal.fire({
					  position: "center",
					  icon: "success",
					  title: "Success",
					  text : 'Resource added successfully',
					  showConfirmButton: true
					});

			this.text = 'Submit'
			this.disabled = false 

			this.name = ''
			this.file = ''			
			}
		
		},

		created(){

		}

	}

</script>