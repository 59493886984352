<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3 class="color-3">Invoices</h3>
					</div>


					<div class="shadow rounded p-3">
						
					<div class="table-responsive">

						<table class="table">

							<thead>
								<tr>
								<th> # </th> <th> Membership number </th> <th>Name</th> <th> Invoice year </th> <th>Package</th> <th> Amount </th> <th> Status </th> <th>Invoice date</th> <th>Comment</th>	
								</tr>
							</thead>


							<tbody>
								
								<tr v-for="i in invoices">
									<td> <router-link :to=" { name : 'invoice-details', params : { id : i.id } } "> {{i.id}} </router-link> </td>
									<td> {{i.membership_number}} </td>
									<td> {{i.first_name}} {{i.last_name}} </td>
									<td> {{i.year}} </td>
									<td> {{i.package_name}} </td>
									<td> Ksh.{{i.cost}} </td>
									<td> {{i.status}} </td>
									<td> {{i.created}} </td>
									<td> {{i.comments}} </td>
								</tr>

							</tbody>
							
						</table>
						

					</div>

					</div>

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			
			invoices : []	
			
			}
		},

		methods : {

			async get_invoices(){

				const res = await axios.get(this.$store.state.url+'api/get-invoices').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.invoices = res 

			}
		
		},

		created(){
			this.get_invoices()
		}

	}

</script>