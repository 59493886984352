import { createStore } from 'vuex'

export default createStore({
  state: {
    url : 'https://mmsserver.coilplay.com/',
    id : localStorage.getItem('id'),
    username : localStorage.getItem('username'),
    logged_in : localStorage.getItem('logged_in'),
    organization_name : localStorage.getItem('organization_name')

  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
