<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3 class="color-3">Membership cards</h3>
					</div>


					<div class="shadow rounded p-3">


					<div style="padding-bottom: 20px;">
						
						<button @click="single_print()" class="btn btn-1" style="margin-right:10px;">Single printing</button>
						<button class="btn btn-2" @click="bulk_print()">Bulk printing</button>

					</div>


					<div class="row" style="padding-bottom:20px;">

						<div class="col-md-6">
							<label>Membership number</label>
							<input type="text" class="form-control" v-model="membership_number" placeholder="Enter membership number">
						</div>
						<div class="col-md-3">
							<label>.</label><br />
							<button @click="search_member()" class="btn btn-1 w-100"> <i class="bi bi-search"></i> Submit </button>
						</div>

						<div class="col-md-3" style="text-align:right;">
							<label>.</label> <br />
							<button v-print="'#badge'"  class="btn btn-2 w-100"> <i class="bi bi-printer"></i> Print</button>
						</div>
						
					</div>



						
					<div  id="badge" style="margin-top: 30px;">

					<div  style="border: solid 1px #ddd; padding:10px; background-color: #fff; background-size: cover; background-position: top; width: 50%; float: left; " v-for="m in members">

						<div style="width: 25%; float: left;">
							<div :style=" { backgroundImage : 'url('+this.$store.state.url+'assets/profile-photos/'+m.photo+')', width:'100%', height:'100px', backgroundSize : 'cover', borderRadius: '10px', border : 'solid 4px #ddd' } "></div>
						
						</div>

						<div style="text-align:left; width: 75%; float: left; padding-left: 20px;">
							<h5 class="color-3"> MMS </h5>
							<h4>{{m.title}}.{{m.first_name}} {{m.last_name}} </h4>
							<h5>{{m.membership_number}}</h5>

							<div>
								<p> Membership : Gold </p>
								<p>Member since : {{m.created}}</p>
							</div>
							
						</div>
					
						
					</div>

					  </div>


					</div>

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
import print from 'vue3-print-nb'


	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			members : [],
			membership_number : '',
			show_all : false		
			}
		},

		directives: {
    print   
},

		methods : {


			print_now(printpage) {
            var headstr = "<html><head><title>Badges</title></head><body'>";
            var footstr = "</body>";
            var newstr = document.all.item(printpage).innerHTML;
            var oldstr = document.body.innerHTML;
            document.body.innerHTML = headstr + newstr + footstr;
            window.print();
            document.body.innerHTML = oldstr;
            return true;
        },

			single_print(){
				this.show_all = false
				this.members = []
			},

			bulk_print(){
				this.show_all = true
				this.get_members()
			},

			async search_member(){
				if(!this.membership_number){
					return
				}
				const res = await axios.post(this.$store.state.url+'api/search-member',{
					membership_number : this.membership_number
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.members = res  
				
			},


			async get_members(){

				const res = await axios.get(this.$store.state.url+'api/get-members').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.members = res  
				
			}
		
		},

		created(){


		}

	}

</script>