<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3 class="color-3">Activities</h3>
					</div>


					<div class="shadow rounded p-3">
						
						
						<div class="table-responsive">

							<table class="table table-hover">

								<thead>
									<tr>
										<th>Title</th>
										<th> Date </th>
										<th> CPD accredited </th>
										<th> Location </th>
										<th>Actions...</th>
									</tr>
								</thead>


								<tbody>
									
									<tr v-for="a in activities">
									
										<td> {{a.title}}</td>
										<td> {{a.activity_date}} to {{a.end_date}} </td>
										<td>
										 <p v-if="a.cpd==1" class="badge bg-success">Yes</p>
										 <p v-if="a.cpd!=1" class="badge bg-danger">No</p>
										 </td>
										<td> {{a.location}} </td>
										<td>
											<router-link :to=" { name : 'activity-details', params : { id : a.id } } " class="btn btn-outline-success"> <i class="bi bi-eye"></i> View</router-link>
										</td>
										
									</tr>

								</tbody>
								
							</table>
							
						</div>



					</div>


					

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			activities : []		
			}
		},

		methods : {
			async list_activities(){

				const res = await axios.get(this.$store.state.url+'api/list-activities').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.activities = res  
				
			}
		
		},

		created(){

			this.list_activities()

		}

	}

</script>