<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3 class="color-3">Activity details</h3>
					</div>


					<div class="shadow rounded p-3">

						<div style="text-align: right;">
							<button @click="delete_activity()" class="btn btn-danger">Delete</button>
						</div>



						<form @submit.prevent="edit_activity()">
						
						<div class="row">
						

							<div class="col-md-12 mt-2">
								<label>Title</label>
								<input  type="text" v-model="title" class="form-control">
							</div>

							<div class="col-md-6 mt-2">
								<label>Start date</label>
								<input  type="date" v-model="date" class="form-control">
							</div>

							<div class="col-md-6 mt-2">
								<label>End date</label>
								<input  type="date" v-model="end_date" class="form-control">
							</div>

							

							<div class="col-md-6 mt-2">
								<label>Location</label>
								<input  type="text" v-model="location" class="form-control">
							</div>

							<div class="col-md-6 mt-2">
								<label>Link</label>
								<input  type="link" v-model="link" class="form-control">
							</div>

							<div class="col-md-12 mt-2">
								<label>Description</label>
								<textarea rows="6" class="form-control" v-model="description"></textarea>
							</div>

							<div class="col-md-4 mt-2">
								<input  type="checkbox" v-model="cpd" value="1"> 
								<label>CPD accredited</label>
							</div>


							<div class="col-md-12 mt-3">
								
								<button class="btn btn-1" :disabled="disabled"> {{text}} </button>
							</div>

						</div>

					</form>
						
						
						
					</div>

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			id : '',
			text : 'Save changes',
			disabled : false,
			title : '',
			date : '',
			cpd : '',
			description : '',
			location : '',
			link : '',
			end_date : ''		
			}
		},

		methods : {



			async delete_activity(){

				const res = await axios.post(this.$store.state.url+'api/delete-activity',{
					id : this.id
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.$router.push('/list-activities')
			
			},

			async edit_activity(){


				if(!this.title){

				Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Title cannot be blank',
					  showConfirmButton: true
					  
					})
				return
				}



				if(!this.date){

				Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Start date cannot be blank',
					  showConfirmButton: true
					  
					})
				return
				}


				if(!this.end_date){

				Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'End date cannot be blank',
					  showConfirmButton: true
					  
					})
				return
				}


				


				if(!this.location){

				Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Location cannot be blank',
					  showConfirmButton: true
					  
					})
				return
				}

				this.text = 'Please wait...'
				this.disabled = true

				const res = await axios.post(this.$store.state.url+'api/edit-activity',{
					title : this.title,
					date : this.date,
					cpd : this.cpd,
					description : this.description,
					location : this.location,
					link : this.link,
					end_date : this.end_date,
					id : this.id 

				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				Swal.fire({
					  position: "center",
					  icon: "success",
					  title: "Success",
					  text : 'Changes saved successfully',
					  showConfirmButton: true
					});

			this.text = 'Save changes'
			this.disabled = false

			this.get_details() 
				
			},


			async get_details(){

				const res = await axios.get(this.$store.state.url+'api/activity-details/'+this.id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				res.forEach((data)=>{
					this.title = data.title 
					this.date = data.activity_date
					this.end_date = data.end_date
					this.cpd_points = data.cpd_points
					this.location = data.location
					this.link = data.link
					this.description = data.description
				})

				
			}
		
		},

		created(){

			this.id = this.$route.params.id
			this.get_details()

		}

	}

</script>