<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3 class="color-3">New memo</h3>
					</div>


					<div class="shadow rounded p-3">
						
						<form @submit.prevent="new_memo()">
						
						<div class="row">
						

							<div class="col-md-12 mt-2">
								<label>Subject</label>
								<input  type="text" v-model="subject" class="form-control" placeholder="Subject of the memo" required>
							</div>

						

							<div class="col-md-12 mt-2">
								<label>Message</label>
								<textarea class="form-control" rows="6" v-model="message" placeholder="Message of the memo"></textarea>
							</div>

		


							<div class="col-md-12 mt-3">
								
								<button class="btn btn-1" :disabled="disabled"> {{text}} </button>
							</div>

						</div>

					</form>

					</div>

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			
			text : 'Submit',
			disabled : false,
			subject : '',
			message : ''			
			}
		},

		methods : {
			async new_memo(){


				if(!this.subject){

				Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Subject cannot be blank',
					  showConfirmButton: true
					  
					})
				return
				}



				if(!this.message){

				Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Message cannot be blank',
					  showConfirmButton: true
					  
					})
				return
				}


				this.text = 'Please wait...'
				this.disabled = true

				const res = await axios.post(this.$store.state.url+'api/new-memo',{
					subject : this.subject,
					message : this.message
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				Swal.fire({
					  position: "center",
					  icon: "success",
					  title: "Success",
					  text : 'Memo added successfully',
					  showConfirmButton: true
					});

			this.text = 'Submit'
			this.disabled = false 

			this.subject = ''
			this.message = ''
			}
		
		},

		created(){

		}

	}

</script>