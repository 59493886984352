<template>


	<!-- for header part -->
	<header> 

		<div class="logosec"> 
			<!--<img src="/assets/images/new-logo.png" class="logo">--> 
			<h6> {{this.$store.state.organization_name}} </h6>

			<button class="btn btn-lg menuicn color-1" id="menuicn" @click="header()"> <i class="bi bi-grid-fill"></i> </button> 
		</div> 

		<div class="searchbar"> 
			<router-link style="margin-right: 10px;" to="/members" class="btn btn-2"> <i class="bi bi-people"></i> Members</router-link>
			<router-link style="margin-right: 10px;" to="/list-resources" class="btn btn-2"> <i class="bi bi-database-gear"></i> Resources</router-link>
			<router-link style="margin-right: 10px;" to="/list-activities" class="btn btn-2"> <i class="bi bi-list"></i> Activities</router-link>
			<router-link to="/profile" class="btn btn-1"> <i class="bi bi-gear"></i> Profile</router-link>

		</div> 

		<div class="message"> 
			<div class="circle"></div>
			<div class="dp">

			</div> 
			Hi,{{this.$store.state.username}}
			

			<router-link  class="btn btn-lg" to="/profile"><i class="bi bi-person"></i>  </router-link>
			 
		</div> 

	</header> 

	


</template>

<script>
	
	export default{
		name : 'header',
		methods : {
			header(){
		let menuicn = document.querySelector(".menuicn"); 
let nav = document.querySelector(".navcontainer"); 
nav.classList.toggle("navclose"); 


			}
		},

		created(){
			
		}
	}

</script>