import { createRouter, createWebHistory } from 'vue-router'
import Login from '../components/Login'
import Dashboard from '../components/Dashboard'
import Profile from '../components/Profile'
import Members from '../components/Members'
import MemberDetails from '../components/MemberDetails'
import NewActivity from '../components/NewActivity'
import ListActivities from '../components/ListActivities'
import ActivityDetails from '../components/ActivityDetails'
import NewResource from '../components/NewResource'
import ListResources from '../components/ListResources'
import ResourceDetails from '../components/ResourceDetails'
import NewPackage from '../components/NewPackage'
import ListPackages from '../components/ListPackages'
import PackageDetails from '../components/PackageDetails'
import NewMemo from '../components/NewMemo'
import ListMemos from '../components/ListMemos'
import MemoDetails from '../components/MemoDetails'
import MembershipCards from '../components/MembershipCards'
import IndividualInvoice from '../components/IndividualInvoice'
import CreateBulkInvoice from '../components/CreateBulkInvoice'
import Invoices from '../components/Invoices'
import InvoiceDetails from '../components/InvoiceDetails'
import Settings from '../components/Settings'





const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },


     {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },

    {
    path: '/profile',
    name: 'profile',
    component: Profile
  },

   {
    path: '/members',
    name: 'members',
    component: Members
  } ,

    {
    path: '/member-details/:id',
    name: 'member-details',
    component: MemberDetails
  }, 


    {
    path: '/new-activity',
    name: 'new-activity',
    component: NewActivity
  }, 

   {
    path: '/list-activities',
    name: 'list-activities',
    component: ListActivities
  }, 


   {
    path: '/activity-details/:id',
    name: 'activity-details',
    component: ActivityDetails
  },

     {
    path: '/new-resource',
    name: 'new-resource',
    component: NewResource
  },

   {
    path: '/list-resources',
    name: 'list-resources',
    component: ListResources
  },

   {
    path: '/resource-details/:id',
    name: 'resource-details',
    component: ResourceDetails
  },


 {
    path: '/new-package',
    name: 'new-package',
    component: NewPackage
  },



 {
    path: '/list-packages',
    name: 'list-packages',
    component: ListPackages
  },


   {
    path: '/package-details/:id',
    name: 'package-details',
    component: PackageDetails
  },

   {
    path: '/new-memo',
    name: 'new-memo',
    component: NewMemo
  },

   {
    path: '/list-memos',
    name: 'list-memo',
    component: ListMemos
  },

   {
    path: '/memo-details/:id',
    name: 'memo-details',
    component: MemoDetails
  },

   {
    path: '/membership-cards',
    name: 'membership-cards',
    component: MembershipCards
  },


     {
    path: '/individual-invoice',
    name: 'individual-invoice',
    component: IndividualInvoice
  },



     {
    path: '/create-bulk-invoice',
    name: 'create-bulk-invoice',
    component: CreateBulkInvoice
  },





     {
    path: '/invoices',
    name: 'invoices',
    component: Invoices
  },



     {
    path: '/invoice-details/:id',
    name: 'invoice-details',
    component: InvoiceDetails
  },

       {
    path: '/settings',
    name: 'settings',
    component: Settings
  }



  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
