<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3 class="color-3">New package</h3>
					</div>


					<div class="shadow rounded p-3">

						<div class="row">
							<div class="col-md-6">
								<router-link to="/list-packages" class="btn p-0 color-1"> <i class="bi bi-chevron-left"></i> Go back </router-link>	
							</div>

							<div class="col-md-6">
								<div style="text-align:right;">
							<button @click="delete_package()" class="btn btn-danger">Delete</button>
						</div>
							</div>

						</div>

						
						
						<form @submit.prevent="edit_package()">
						<div class="row">
						

							<div class="col-md-4 mt-2">
								<label>Name</label>
								<input  type="text" v-model="name" class="form-control" placeholder="Name of the package" required>
							</div>

							<div class="col-md-4 mt-2">
								<label>Cost(Ksh)</label>
								<input  type="number" min="0" v-model="cost" class="form-control" placeholder="Cost of the package" required>
							</div>

							<div class="col-md-4 mt-2">
								<label>Prefix</label>
								<input  type="text"  v-model="prefix" class="form-control" placeholder="Prefix" required>
							</div>

							<div class="col-md-12 mt-2">
								<label>Description</label>
								<textarea class="form-control" rows="6" v-model="description" placeholder="Enter description"></textarea>
							</div>

		


							<div class="col-md-12 mt-3">
								
								<button class="btn btn-1" :disabled="disabled"> {{text}} </button>
							</div>

						</div>

					</form>

					</div>

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			
			text : 'Save changes',
			disabled : false,
			name : '',
			cost : '',
			description : '',
			id : '',
			prefix : ''	
			
			}
		},

		methods : {

			async delete_package(){

				const res = await axios.post(this.$store.state.url+'api/delete-package',{
					id  : this.id 
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				
				this.$router.push('/list-packages')

			},

			async package_details(){

				const res = await axios.get(this.$store.state.url+'api/package-details/'+this.id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				res.forEach((data)=>{
					this.name = data.name 
					this.cost = data.cost
					this.description = data.description
					this.prefix = data.prefix
				})

			},

			async edit_package(){


				if(!this.name){

				Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Package name cannot be blank',
					  showConfirmButton: true
					  
					})
				return
				}



				if(!this.cost){

				Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Cost date cannot be blank',
					  showConfirmButton: true
					  
					})
				return
				}


				if(!this.prefix){

				Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Prefix cannot be blank',
					  showConfirmButton: true
					  
					})
				return
				}


				this.text = 'Please wait...'
				this.disabled = true

				const res = await axios.post(this.$store.state.url+'api/edit-package',{
					id : this.id,
					name : this.name,
					cost : this.cost,
					description : this.description,
					prefix : this.prefix

				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				Swal.fire({
					  position: "center",
					  icon: "success",
					  title: "Success",
					  text : 'Changes saved successfully',
					  showConfirmButton: true
					});

			this.text = 'Save changes'
			this.disabled = false 
			this.package_details()	
			}
		
		},

		created(){
			this.id = this.$route.params.id 
			this.package_details()
		}

	}

</script>