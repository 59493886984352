<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3 class="color-3">Memos</h3>
					</div>


					<div class="shadow rounded p-3">
						
						<table class="table table-hover">

							<thead>
								<tr>
									<th> Subject </th>
									<th> Date </th>
									<th> Action... </th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="m in memos">
									<td> {{m.subject}} </td>
									<td> {{m.created}} </td>
									<td>
										<router-link :to=" { name : 'memo-details', params : { id : m.id } } " class="btn btn-outline-success"> <i class="bi bi-eye"></i> View </router-link>
									</td>
								</tr>
							</tbody>
							
						</table>

					</div>

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			
			memos : [],
			
			}
		},

		methods : {

			async get_memos(){

				const res = await axios.get(this.$store.state.url+'api/get-memos').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

			this.memos = res 

			}
		
		},

		created(){
			this.get_memos()
		}

	}

</script>