<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3 class="color-3">Settings</h3>
					</div>


					<div class="shadow rounded p-3">
						
						<form @submit.prevent="new_settings()">
						
						<div class="row">


							<div class="col-md-4 mt-2">
								<label>Organization name</label>
								<input  type="text" min="1" max="31" v-model="organization_name" class="form-control" placeholder="Enter organization name">
							</div>
						

							<div class="col-md-4 mt-2">
								<label>Year starting day</label>
								<input  type="number" min="1" max="31" v-model="year_starting_day" class="form-control" placeholder="Enter year starting day">
							</div>


							<div class="col-md-4 mt-2">
								<label>Year starting month</label>
								<input type="number" min="1" max="12" v-model="year_starting_month" class="form-control" placeholder="Enter year starting month">
							</div>


							<div class="col-md-12 mt-3">
								<button @click="load_settings()" type="button" style="margin-right:10px;" class="btn btn-outline-success"> <i class="bi bi-arrow-clockwise"></i> Reload</button>
								<button class="btn btn-1" :disabled="disabled"> {{text}} </button>
							</div>

						</div>

					</form>

					</div>

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			year_starting_day : '',
			year_starting_month : '',
			text : 'Save changes',
			disabled : false,
			id : this.$store.state.id,
			organization_name : ''	
			
			}
		},

		methods : {

			async load_settings(){

				const res = await axios.get(this.$store.state.url+'api/load-settings').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				}) 

				res.forEach((data)=>{
					this.year_starting_day = data.year_starting_day
					this.year_starting_month = data.year_starting_month
					this.organization_name = data.organization_name
					localStorage.setItem('organization_name',data.organization_name)
					this.$store.state.organization_name = data.organization_name
				})
				
			},


			async new_settings(){

				if(!this.year_starting_day){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Year starting day is required!",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}


				if(!this.year_starting_month){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Year starting month is required!",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}

				if(!this.organization_name){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Organization name is required!",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}


				this.text = 'Please wait...'
				this.disabled = true


				const res = await axios.post(this.$store.state.url+'api/new-settings',{
					year_starting_day : this.year_starting_day,
					year_starting_month : this.year_starting_month,
					organization_name : this.organization_name

				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				Swal.fire({
					  position: "top-center",
					  icon: "success",
					  title: "Changes saved successfully",
					  showConfirmButton: false,
					  timer: 1500
					});

			this.text = 'Save changes'
			this.disabled = false 

			this.load_settings()
				
			}
		
		},

		created(){
			this.load_settings();
		}

	}

</script>