<template>
	

	<section style="padding-top:20px; padding-bottom: 20px;">
		

		<div class="container">

			<div class="row">

				<div class="col-md-1"></div>
				
				<div class="col-md-2" style="background-image: url('/images/portrait-happy-young-african-couple-using-laptop-computer (1).jpg'); background-position: center; background-size: cover; border-radius: 50px; margin-right: 10px;"></div>

			<div class="col-md-2" style="background-image: url('/images/young-afro-man-listening-music-with-headphones (1).jpg'); background-position: center; background-size: cover; border-radius: 40px;"></div>


				<div class="col-md-4">
					
					<div style="padding-top: 50px; padding-bottom: 50px;">
						
						<div style="text-align: center;">
							<h3> {{this.$store.state.organization_name}}</h3>
						<h6>Welcome Back!</h6>
						<h1>Login</h1>
						</div>

						<form @submit.prevent="login()">
							
							<div>
								<label>Username <span class="text-danger">*</span> </label>
								<input type="text" v-model="username" class="form-control" placeholder="Enter username">
							</div>


							<div class="mt-2">
								<label>Password <span class="text-danger">*</span> </label>
								<input type="password" v-model="password" class="form-control" placeholder="Your password">
							</div>

							<div class="mt-3" style="text-align:center;">
								<button class="btn btn-1 w-100" :disabled="disabled">{{text}}</button>
								
							</div>

							

							<div style="text-align:center; padding-top: 30px;">
								<p class="color-3">MMS.All rights reserved</p>
								<p class="color-3"> MMS © 2024. All Rights Reserved </p>
							</div>

						</form>

					</div>

				</div>
				
			</div>
			
		</div>


	</section>


</template>







<script>
	import axios from 'axios'
	import Swal from 'sweetalert2'
	export default{
		name : 'register',
		data(){
			return {
				username : '',
				password : '',
				text : 'Login',
				disabled : false
			}
		},

		methods : {

			async load_settings(){

				const res = await axios.get(this.$store.state.url+'api/load-settings').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				}) 

				res.forEach((data)=>{
					localStorage.setItem('organization_name',data.organization_name)
					this.$store.state.organization_name = data.organization_name
				})
				
			},

			async login(){
				
				if(!this.username){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title : 'Error',
					  text: 'Username cannot be blank',
					  showConfirmButton: true,
					  
					})
					return
				}

				if(!this.password){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title : 'Error',
					  text: 'Password cannot be blank',
					  showConfirmButton: true,
					  
					})
					return
				}

				this.text = 'Please wait...'
				this.disabled = true

				const res = await axios.post(this.$store.state.url+'api/admin-login',{

					username : this.username,
					password : this.password,
		

				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				if(res.length>0){

					res.forEach((data)=>{
						localStorage.setItem('id',data.id)
						this.$store.state.id = data.id

						localStorage.setItem('username',data.username)
						this.$store.state.username = data.username

						localStorage.setItem('logged_in','yes')
						this.$store.state.logged_in = 'yes'


					})

					this.$router.push('/dashboard')


				}else{

					Swal.fire({
					  position: "center",
					  icon: "error",
					  title : 'Error',
					  text: 'Invalid login credentials!',
					  showConfirmButton: true,
					  
					})

				}

				this.text = 'Login'
				this.disabled = false
				
			}


		},

		created(){
		
		this.load_settings()

		}

	}
</script>

