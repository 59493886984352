<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3 class="color-3">Invoices</h3>
					</div>


					<div class="shadow rounded p-3">
						
					<div class="table-responsive">

						<table class="table">

							<thead>
								<tr>
								<th> # </th> <th> Membership number </th> <th>Name</th> <th> Invoice year </th> <th>Package</th> <th> Amount </th> <th> Status </th> <th>Invoice date</th>	
								</tr>
							</thead>


							<tbody>
								
								<tr v-for="i in details">
									<td> {{i.id}} </td>
									<td> {{i.membership_number}} </td>
									<td> {{i.first_name}} {{i.last_name}} </td>
									<td> {{i.year}} </td>
									<td> {{i.package_name}} </td>
									<td> Ksh.{{i.cost}} </td>
									<td> {{status}} </td>
									<td> {{i.created}} </td>
								</tr>

							</tbody>
							
						</table>
						

					</div>

					</div>







					<div class="shadow rounded p-3 mt-3">

						<div class="mb-3" v-if="balance>0 ">
							<button @click="show_modal=true" class="btn btn-outline-success">Record payment + </button>
						</div>


						<div class="mb-3" v-if="balance<=0 && show_message">
							<div class="row" style="background-color:#fafafa; border-left: solid 4px green; padding: 10px;">

								<div class="col-md-1 col-2" style="text-align:center;">
									<h3><i class="bi bi-info-circle"></i></h3>
								</div>

								<div class="col-md-11 col-10" style="border-left: 1px #ddd solid;">
									<p>This invoice has fully been paid</p>
								</div>
								
							</div>
						</div>

						<h6>Payment records</h6>


						<div class="table-responsive">

							<table class="table">

								<thead>
									<tr>
									<th> # </th>	<th> Amount </th> <th> Payment mode </th> <th> Payment date </th> <th> Comments </th>
									</tr>
								</thead>

								<tbody>
									
									<tr v-for="p in payments">
										<td> <button @click="payment_details(p.id)" class="btn text-success"> <u> {{ p.id }}  </u> </button> </td>
										<td> Ksh.{{ p.amount }} </td>
										<td> {{ p.payment_mode }} </td>
										<td> {{ p.payment_date }} </td>
										<td> {{ p.comments }} </td>
									</tr>

								</tbody>
								
							</table>
							
						</div>




						<div>
							<h6> Total paid : Ksh.{{total_paid}} </h6>
							<h6> Balance : Ksh.{{balance}} </h6>
						</div>


						
					</div>






					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 








	<!-- modal 1 -->


	<div style="position: fixed; top: 0; right: 0; width: 100%; z-index: 900; padding-top: 80px;"  v-if="show_modal" >
		
		<div class="container">

			<div class="row">

				<div class="col-md-4"></div>
				<div class="col-md-4">
					
					<div style="background-color: #fff;" class="p-3 shadow-lg rounded">

						<div class="row">

							<div class="col-md-6 mt-2">
							<h6>Record payment</h6>	
							</div>

							<div class="col-md-6" style="text-align:right;">
								<button @click="show_modal=false" class="btn btn-lg text-danger"> <i class="bi bi-x"></i> </button>
							</div>
							
						</div>

						


						<form @submit.prevent="make_payment()">
							
							<div>
								<label>Mode of payment</label>
								<select class="form-select" v-model="payment_mode">
									<option>MPESA</option>
									<option>CASH</option>
									<option>BANK</option>
								</select>
							</div>

							<div>
								<label>Date</label>
								<input type="date" class="form-control" v-model="payment_date">
							</div>

							<div>
								<label>Amount</label>
								<input type="text" disabled class="form-control" v-model="amount">
							</div>

							<div>
								<label>Comments</label>
								<input type="text" class="form-control" v-model="comments">
							</div>

							<div class="mt-3">
							<button class="btn btn-1 w-100" :disabled="disabled" >{{text}}</button>
							</div>




						</form>


					</div>

				</div>
				
			</div>
			
		</div>


	</div>
	










	<!-- modal 2 -->


	<div style="position: fixed; top: 0; right: 0; width: 100%; z-index: 900; padding-top: 80px;"  v-if="show_modal2" >
		
		<div class="container">

			<div class="row">

				<div class="col-md-4"></div>
				<div class="col-md-4">
					
					<div style="background-color: #fff;" class="p-3 shadow-lg rounded">

						<div class="row">

							<div class="col-md-6 mt-2">
							<h6>Record payment</h6>	
							</div>

							<div class="col-md-6" style="text-align:right;">
								<button @click="show_modal2=false" class="btn btn-lg text-danger"> <i class="bi bi-x"></i> </button>
							</div>
							
						</div>

						


						<form @submit.prevent="edit_payment()">
							
							<div>
								<label>Mode of payment</label>
								<select class="form-select" v-model="payment_mode">
									<option>MPESA</option>
									<option>CASH</option>
									<option>BANK</option>
								</select>
							</div>

							<div>
								<label>Date</label>
								<input type="date" class="form-control" v-model="payment_date">
							</div>

							<div>
								<label>Amount</label>
								<input type="text" disabled class="form-control" v-model="amount">
							</div>

							<div>
								<label>Comments</label>
								<input type="text" class="form-control" v-model="comments">
							</div>

							<div class="mt-3">
							<button class="btn btn-1 w-100" :disabled="disabled" >{{text}}</button>
							</div>

							<div class="mt-3">
							<button @click="delete_payment()" type="button" class="btn btn-danger w-100"> <i class="bi bi-x"></i> Delete payment </button>
							</div>




						</form>


					</div>

				</div>
				
			</div>
			
		</div>


	</div>
	









</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			
			details : [],
			id : '',
			amount : '',
			payment_mode : '',
			comments : '',
			payment_date : '',
			text : 'Submit',
			disabled : false,
			show_modal : false,
			payments : [],
			show_modal2 : false,
			payment_id : '',
			cost : 0,
			status : '',
			total_paid : 0,
			membership_number : '',
			balance : '',
			invoice_year : '',
			show_message : false	
			
			}
		},

		methods : {


			async edit_payment(){


				if(!this.payment_mode){

				Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Payment mode cannot be blank',
					  showConfirmButton: true
					  
					})
				return
				}



				if(!this.payment_date){

				Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Payment date cannot be blank',
					  showConfirmButton: true
					  
					})
				return
				}


				if(!this.amount){

				Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Amount cannot be blank',
					  showConfirmButton: true
					  
					})
				return
				}


				this.text = 'Please wait...'
				this.disabled = true

				const res = await axios.post(this.$store.state.url+'api/edit-payment',{
					payment_mode : this.payment_mode,
					payment_date : this.payment_date,
					amount : this.amount,
					comments : this.comments,
					id : this.payment_id 
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				Swal.fire({
					  position: "center",
					  icon: "success",
					  title: "Success",
					  text : 'Changes saved',
					  showConfirmButton: true
					});

			this.text = 'Submit'
			this.disabled = false 

			this.invoice_payments()
			this.show_modal2 = false

			}, 


			async delete_payment(){
				 
				const res = await axios.post(this.$store.state.url+'api/delete-payment',{
					id : this.payment_id,
					invoice_id : this.id
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.invoice_payments()
				this.show_modal2 = false

			},

			async payment_details(id){
				this.payment_id = id 
				const res = await axios.post(this.$store.state.url+'api/payment-details',{
					id : id
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				res.forEach((data)=>{
					this.payment_mode = data.payment_mode
					this.payment_date = data.payment_date
					this.amount = data.amount
					this.comments = data.comments
				}) 

				this.show_modal2 = true

			},

			async invoice_payments(){
				this.total_paid = 0

				const res = await axios.get(this.$store.state.url+'api/invoice-payments/'+this.id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.payments = res 

				res.forEach((data)=>{
					this.total_paid = this.total_paid + parseInt(data.amount)
				})


				if(this.total_paid>=this.cost && this.payments.length>0 ){
					this.status = 'Fully paid'
				}else if(this.total_paid<this.cost && this.payments.length>0 ){
					this.status = 'Partialy paid'
				}else{
					this.status  = 'Not paid'
				}

				this.balance = this.cost - this.total_paid 

			},


			async make_payment(){


				if(!this.payment_mode){

				Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Payment mode cannot be blank',
					  showConfirmButton: true
					  
					})
				return
				}



				if(!this.payment_date){

				Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Payment date cannot be blank',
					  showConfirmButton: true
					  
					})
				return
				}


				if(!this.amount){

				Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Amount cannot be blank',
					  showConfirmButton: true
					  
					})
				return
				}


				this.text = 'Please wait...'
				this.disabled = true

				const res = await axios.post(this.$store.state.url+'api/record-payment',{
					payment_mode : this.payment_mode,
					payment_date : this.payment_date,
					amount : this.amount,
					comments : this.comments,
					invoice_id : this.id,
					membership_number : this.membership_number,
					invoice_year : this.invoice_year 
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				Swal.fire({
					  position: "center",
					  icon: "success",
					  title: "Success",
					  text : 'Payment recorded  successfully',
					  showConfirmButton: true
					});

			this.text = 'Submit'
			this.disabled = false 

			this.show_modal = false
			

			this.invoice_payments()

			}, 

			async get_details(){

				const res = await axios.get(this.$store.state.url+'api/invoice-details/'+this.id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.details = res 

				res.forEach((data)=>{
					this.amount = data.cost
					this.cost = data.cost
					this.membership_number = data.membership_number
					this.invoice_year = data.year
				})

				this.invoice_payments()	

			}
		
		},

		created(){
			this.id = this.$route.params.id
			this.get_details()

			setTimeout(()=>{
				this.show_message = true
			}, 3000);
			
		}

	}

</script>