<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3 class="color-3">Bulk invoice</h3>
					</div>


					<div class="shadow rounded p-3">
						
						<form @submit.prevent="new_invoice()">
						
						<div class="row">
						

							<div class="col-md-6 mt-2">

								<label>Invoice year</label>
								<select class="form-select" v-model="year">
									<option v-for="y in years">{{y}}</option>
								</select>
							</div>


							<div class="col-md-6 mt-2">
								<label>Comments</label>
								<input  type="text" v-model="comments" class="form-control" placeholder="Enter comments(Optional)">
							</div>


							<div class="col-md-12 mt-3">
								
								<button class="btn btn-1" :disabled="disabled"> {{text}} </button>
							</div>

						</div>

					</form>

					</div>

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			
			text : 'Submit',
			disabled : false,
			year : '',
			years : [],
			comments : ''	
			
			}
		},

		methods : {

			generate_years(){
				let difference = 60;
				let counter = 0;
				let start_year = 2000;
				while(counter<difference){
					counter++;
					this.years = this.years.concat( parseInt(start_year) + parseInt(counter) )
				}
			},

			async new_invoice(){

				if(!this.year){

				Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Year cannot be blank',
					  showConfirmButton: true
					  
					})
				return
				}


				this.text = 'Please wait...'
				this.disabled = true

				const res = await axios.post(this.$store.state.url+'api/create-bulk-invoice',{
					year : this.year,
					comments : this.comments

				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				if(res=='success'){

					Swal.fire({
					  position: "center",
					  icon: "success",
					  title: "Success",
					  text : 'Invoice(s) created successfully',
					  showConfirmButton: true
					});

					this.year = ''
					this.comments = ''

				}else{
					Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : res,
					  showConfirmButton: true
					});
				}

			this.text = 'Submit'
			this.disabled = false 

			


			}
		
		},

		created(){
			this.generate_years()
		}

	}

</script>