<template>
	<div class="navcontainer"> 
			<nav class="nav"> 
				<div class="nav-upper-options"> 
					<div class="nav-option"> 
					<router-link class="btn option1" to="/dashboard"><i class="bi bi-speedometer"></i> Dashboard</router-link> 
					</div> 


	

	<div class="nav-option"> 
					<router-link class="btn option1" to="/members"><i class="bi bi-people"></i> Members</router-link> 
					</div>

				

						<div class="nav-option"> 
					<router-link class="btn option1" to="/membership-cards"><i class="bi bi-person-vcard"></i> Membership cards</router-link> 
					</div>


							<div class="nav-option">
	<div class="dropdown">
  <button class="btn option1 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
     <i class="bi bi-layers"></i> Packages
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
   
    <li><router-link to="/new-package" class="dropdown-item">Add new</router-link></li>

 <li><router-link to="/list-packages" class="dropdown-item">List packages</router-link></li>



  </ul>
</div>
</div>






	<div class="nav-option">
	<div class="dropdown">
  <button class="btn option1 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
     <i class="bi bi-currency-dollar"></i> Invoicing
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
   
    <li><router-link to="/individual-invoice" class="dropdown-item">Create individual</router-link></li>

 <li><router-link to="/create-bulk-invoice" class="dropdown-item">Create bulk</router-link></li>

  <li><router-link to="/invoices" class="dropdown-item">View invoices</router-link></li>



  </ul>
</div>
</div>











										
		<div class="nav-option">
	<div class="dropdown">
  <button class="btn option1 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
     <i class="bi bi-list"></i> Activities
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
   
    <li><router-link to="/new-activity" class="dropdown-item">New activity</router-link></li>

 <li><router-link to="/list-activities" class="dropdown-item">List activities</router-link></li>



  </ul>
</div>
</div>



		<div class="nav-option">
	<div class="dropdown">
  <button class="btn option1 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
     <i class="bi bi-database-gear"></i> Resources
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
   
    <li><router-link to="/new-resource" class="dropdown-item">Add new</router-link></li>

 <li><router-link to="/list-resources" class="dropdown-item">List resources</router-link></li>



  </ul>
</div>
</div>




	<div class="nav-option">
	<div class="dropdown">
  <button class="btn option1 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
     <i class="bi bi-grid"></i> Memos
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
   
    <li><router-link to="/new-memo" class="dropdown-item">Add new</router-link></li>

 <li><router-link to="/list-memos" class="dropdown-item">List memos</router-link></li>



  </ul>
</div>
</div>




<div class="nav-option"> 
	<router-link class="btn option1" to="/settings"><i class="bi bi-gear"></i> Settings</router-link> 
	</div>

					


	<div class="nav-option"> 
	<router-link class="btn option1" to="/profile"><i class="bi bi-person"></i> Profile</router-link> 
	</div>



						<div class="nav-option"> 
					<button @click="logout()" class="btn option1"><i class="bi bi-power"></i> Log Out</button> 
					</div> 


					
				</div> 
			</nav> 
		</div>
</template>


<script>
	export default{

		name : 'sidebar',

		methods : {
			logout(){
				localStorage.clear()
				this.$router.push('/')
			}
		}

	}

</script>