<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div>
				<h6 class="color-3">Logged in as <b>  {{this.$store.state.username}} </b></h6>
			</div>


			<div class="row">

				<div class="col-md-6">
					<div>
				<h3 class="color-3">Welcome Back!</h3>
			</div>
				</div>


				<div class="col-md-6" style="text-align: right;">

					{{date}}
					
				</div>
				
			</div>


			<div class="row">

				<div class="col-md-3 mt-3">

					<div class="shadow rounded p-2 card-design-2">
					
					<div class="row">

						<div class="col-md-8 col-8">
							<h3 class="color-1"> {{members.length}} </h3>
							<p>Members</p>
						</div>

						<div class="col-md-4 col-4" style="text-align:right;">
						<h1> <i class="bi bi-people color-1"></i> </h1>
						</div>
						
					</div>	


					<div class="border-top">
						<router-link class="btn" to="/members"> <i class="bi bi-eye color-1"></i> View members </router-link>
					</div>


					</div>
					

				</div>







				<div class="col-md-3 mt-3">

					<div class="shadow rounded p-2 card-design-2">
					
					<div class="row">

						<div class="col-md-8 col-8">
							<h3 class="color-1"> {{packages.length}} </h3>
							<p>Packages</p>
						</div>

						<div class="col-md-4 col-4" style="text-align:right;">
						<h1> <i class="bi bi-layers color-1"></i> </h1>
						</div>
						
					</div>	


					<div class="border-top">
						<router-link class="btn" to="/list-packages"> <i class="bi bi-eye color-1"></i> View packages </router-link>
					</div>


					</div>
					

				</div>



					<div class="col-md-3 mt-3">

					<div class="shadow rounded p-2 card-design-2">
					
					<div class="row">

						<div class="col-md-8 col-8">
							<h3 class="color-1"> {{activities.length}} </h3>
							<p>Activities</p>
						</div>

						<div class="col-md-4 col-4" style="text-align:right;">
						<h1> <i class="bi bi-list color-1"></i> </h1>
						</div>
						
					</div>	


					<div class="border-top">
						<router-link class="btn" to="/list-activities"> <i class="bi bi-eye color-1"></i> Activities </router-link>
					</div>


					</div>
					

				</div>





					<div class="col-md-3 mt-3">

					<div class="shadow rounded p-2 card-design-2">
					
					<div class="row">

						<div class="col-md-8 col-8">
							<h3 class="color-1"> {{resources.length}} </h3>
							<p>Resources</p>
						</div>

						<div class="col-md-4 col-4" style="text-align:right;">
						<h1> <i class="bi bi-database-gear color-1"></i> </h1>
						</div>
						
					</div>	


					<div class="border-top">
						<router-link class="btn" to="/list-resources"> <i class="bi bi-eye color-1"></i> Resources </router-link>
					</div>


					</div>
					

				</div>





				<div class="col-md-3 mt-3">

					<div class="shadow rounded p-2 card-design-2">
					
					<div class="row">

						<div class="col-md-8 col-8">
							<h3 class="color-1"> {{memos.length}} </h3>
							<p>Memos</p>
						</div>

						<div class="col-md-4 col-4" style="text-align:right;">
						<h1> <i class="bi bi-grid color-1"></i> </h1>
						</div>
						
					</div>	


					<div class="border-top">
						<router-link class="btn" to="/list-memos"> <i class="bi bi-eye color-1"></i> Memos </router-link>
					</div>


					</div>
					

				</div>




				<div class="col-md-3 mt-3">

					<div class="shadow rounded p-2 card-design-2">
					
					<div class="row">

						<div class="col-md-8 col-8">
							<h3 class="color-1"> {{members.length}} </h3>
							<p>Cards</p>
						</div>

						<div class="col-md-4 col-4" style="text-align:right;">
						<h1> <i class="bi bi-person-vcard color-1"></i> </h1>
						</div>
						
					</div>	


					<div class="border-top">
						<router-link class="btn" to="/membership-cards"> <i class="bi bi-eye color-1"></i> View cards </router-link>
					</div>


					</div>
					

				</div>





				
			</div>





			<div style="padding-top:30px;">
						<h3 class="color-3">Activities</h3>
					</div>


					<div class="shadow rounded p-3">
						
						
						<div class="table-responsive">

							<table class="table table-hover">

								<thead>
									<tr>
										<th>Title</th>
										<th> Date </th>
										<th> CPD accredited </th>
										<th> Location </th>
										<th>Actions...</th>
									</tr>
								</thead>


								<tbody>
									
									<tr v-for="a in activities">
									
										<td> {{a.title}}</td>
										<td> {{a.activity_date}} to {{a.end_date}} </td>
										<td>
										 <p v-if="a.cpd==1" class="badge bg-success">Yes</p>
										 <p v-if="a.cpd!=1" class="badge bg-danger">No</p>
										 </td>
										<td> {{a.location}} </td>
										<td>
											<router-link :to=" { name : 'activity-details', params : { id : a.id } } " class="btn btn-outline-success"> <i class="bi bi-eye"></i> View</router-link>
										</td>
										
									</tr>

								</tbody>
								
							</table>
							
						</div>



					</div>



			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
				members : [],
				packages : [],
				activities : [],
				resources : [],
				memos : [],
				date : ''
			}
		},

		methods : {

			async get_memos(){

				const res = await axios.get(this.$store.state.url+'api/get-memos').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

			this.memos = res 

			},

			async list_resources(){

				const res = await axios.get(this.$store.state.url+'api/list-resources').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.resources = res  
				
			},

			async list_activities(){

				const res = await axios.get(this.$store.state.url+'api/list-activities').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.activities = res  
				
			},

			async get_packages(){

				const res = await axios.get(this.$store.state.url+'api/get-packages').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.packages = res 	
			},

			async get_members(){

				const res = await axios.get(this.$store.state.url+'api/get-members').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.members = res  
				
			}
		
		},

		created(){

			this.date = new Date()

			this.get_members()
			this.get_packages()
			this.list_activities()
			this.list_resources()
			this.get_memos()
		}

	}

</script>