<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3 class="color-3">New package</h3>
					</div>


					<div class="shadow rounded p-3">
						
						<form @submit.prevent="new_package()">
						
						<div class="row">
						

							<div class="col-md-4 mt-2">
								<label>Name</label>
								<input  type="text" v-model="name" class="form-control" placeholder="Name of the package" required>
							</div>

							<div class="col-md-4 mt-2">
								<label>Cost(Ksh)</label>
								<input  type="number" min="0" v-model="cost" class="form-control" placeholder="Cost of the package" required>
							</div>

							<div class="col-md-4 mt-2">
								<label>Prefix</label>
								<input  type="text"  v-model="prefix" class="form-control" placeholder="Enter prefix" required>
							</div>

							<div class="col-md-12 mt-2">
								<label>Description</label>
								<textarea class="form-control" rows="6" v-model="description" placeholder="Enter description"></textarea>
							</div>

		


							<div class="col-md-12 mt-3">
								
								<button class="btn btn-1" :disabled="disabled"> {{text}} </button>
							</div>

						</div>

					</form>

					</div>

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			
			text : 'Submit',
			disabled : false,
			name : '',
			cost : '',
			description : '',
			prefix : ''	
			
			}
		},

		methods : {
			async new_package(){


				if(!this.name){

				Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Package name cannot be blank',
					  showConfirmButton: true
					  
					})
				return
				}



				if(!this.cost){

				Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Cost date cannot be blank',
					  showConfirmButton: true
					  
					})
				return
				}



				if(!this.prefix){

				Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Prefix cannot be bank!',
					  showConfirmButton: true
					  
					})
				return
				}


				this.text = 'Please wait...'
				this.disabled = true

				const res = await axios.post(this.$store.state.url+'api/new-package',{
					name : this.name,
					cost : this.cost,
					description : this.description,
					prefix : this.prefix

				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				Swal.fire({
					  position: "center",
					  icon: "success",
					  title: "Success",
					  text : 'Package added successfully',
					  showConfirmButton: true
					});

			this.text = 'Submit'
			this.disabled = false 

			this.name = ''
			this.cost = ''
			this.description = ''	
			}
		
		},

		created(){

		}

	}

</script>