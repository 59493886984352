<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3 class="color-3">New activity</h3>
					</div>


					<div class="shadow rounded p-3">
						
						<form @submit.prevent="new_activity()">
						
						<div class="row">
						

							<div class="col-md-12 mt-2">
								<label>Title</label>
								<input  type="text" v-model="title" class="form-control">
							</div>

							<div class="col-md-6 mt-2">
								<label>Start date</label>
								<input  type="date" v-model="date" class="form-control">
							</div>

							<div class="col-md-6 mt-2">
								<label>End date</label>
								<input  type="date" v-model="end_date" class="form-control">
							</div>

							

							<div class="col-md-6 mt-2">
								<label>Location</label>
								<input  type="text" v-model="location" class="form-control">
							</div>

							<div class="col-md-6 mt-2">
								<label>Link</label>
								<input  type="link" v-model="link" class="form-control">
							</div>

							<div class="col-md-12 mt-2">
								<label>Description</label>
								<textarea rows="6" class="form-control" v-model="description"></textarea>
							</div>

							<div class="col-md-4 mt-2">
								<input  type="checkbox" v-model="cpd" value="1"> 
								<label>CPD accredited</label>
							</div>


							<div class="col-md-12 mt-3">
								
								<button class="btn btn-1" :disabled="disabled"> {{text}} </button>
							</div>

						</div>

					</form>

					</div>

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			
			text : 'Submit',
			disabled : false,
			title : '',
			date : '',
			cpd : '',
			description : '',
			location : '',
			link : '',
			end_date : ''	
			
			}
		},

		methods : {
			async new_activity(){


				if(!this.title){

				Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Title cannot be blank',
					  showConfirmButton: true
					  
					})
				return
				}



				if(!this.date){

				Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Start date cannot be blank',
					  showConfirmButton: true
					  
					})
				return
				}


				if(!this.end_date){

				Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'End date cannot be blank',
					  showConfirmButton: true
					  
					})
				return
				}




				if(!this.location){

				Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Location cannot be blank',
					  showConfirmButton: true
					  
					})
				return
				}

				this.text = 'Please wait...'
				this.disabled = true

				const res = await axios.post(this.$store.state.url+'api/new-activity',{
					title : this.title,
					date : this.date,
					cpd : this.cpd,
					description : this.description,
					location : this.location,
					link : this.link,
					end_date : this.end_date

				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				Swal.fire({
					  position: "center",
					  icon: "success",
					  title: "Success",
					  text : 'Activity added successfully',
					  showConfirmButton: true
					});

			this.text = 'Submit'
			this.disabled = false 

			this.title = ''
			this.date = ''
			this.cpd_points = ''
			this.description = ''
			this.location = ''
			this.link = ''
			this.end_date = ''
				
			}
		
		},

		created(){

		}

	}

</script>