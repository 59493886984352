<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3 class="color-3">Resources</h3>
					</div>


					<div class="shadow rounded p-3">
						
						
						<div class="table-responsive">

							<table class="table table-hover">

								<thead>
									<tr>
										<th>Name</th>
										<th> Actions... </th>
										
									</tr>
								</thead>


								<tbody>
									
									<tr v-for="r in resources">
									
										<td> {{r.name}}</td>
										<td>
											
										<a  :href="this.$store.state.url+'assets/resources/'+r.file" class="btn btn-outline-success" style="margin-right:10px;" :download="r.file" target="_blank"> <i class="bi bi-arrow-down"></i> Download</a>

										<router-link :to=" { name : 'resource-details', params : { id : r.id } } " class="btn btn-1" style="margin-right:10px;">  Edit</router-link>

										<button @click="delete_file(r.id)" class="btn btn-danger"> <i class="bi bi-x"></i> Delete</button>

										</td>										
									</tr>

								</tbody>
								
							</table>
							
						</div>



					</div>

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			resources : []		
			}
		},

		methods : {

			async delete_file(id){

				const res = await axios.post(this.$store.state.url+'api/delete-resource',{
					id : id 
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.list_resources()  
				
			},

			async list_resources(){

				const res = await axios.get(this.$store.state.url+'api/list-resources').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.resources = res  
				
			}
		
		},

		created(){

			this.list_resources()

		}

	}

</script>