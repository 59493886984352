<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3 class="color-3">New memo</h3>
					</div>


					<div class="shadow rounded p-3">

						<div class="row">
							<div class="col-md-6">
								<router-link to="/list-memos" class="btn p-0 color-1"> <i class="bi bi-chevron-left"></i> Go back </router-link>	
							</div>

							<div class="col-md-6">
								<div style="text-align:right;">
							<button @click="delete_memo()" class="btn btn-danger"> <i class="bi bi-x"></i> Delete</button>
						</div>
							</div>

						</div>
						
						<form @submit.prevent="edit_memo()">
						
						<div class="row">
						

							<div class="col-md-12 mt-2">
								<label>Subject</label>
								<input  type="text" v-model="subject" class="form-control" placeholder="Subject of the memo" required>
							</div>

						

							<div class="col-md-12 mt-2">
								<label>Message</label>
								<textarea class="form-control" rows="6" v-model="message" placeholder="Message of the memo"></textarea>
							</div>

		


							<div class="col-md-12 mt-3">
								
								<button class="btn btn-1" :disabled="disabled"> {{text}} </button>
							</div>

						</div>

					</form>

					</div>

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			
			text : 'Save changes',
			disabled : false,
			subject : '',
			message : '',
			id : ''			
			}
		},

		methods : {

			async delete_memo(){

				const res = await axios.post(this.$store.state.url+'api/delete-memo',{
					id  : this.id 
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				
				this.$router.push('/list-memos')

			},

			async memo_details(){

				const res = await axios.get(this.$store.state.url+'api/memo-details/'+this.id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				res.forEach((data)=>{
					this.subject = data.subject
					this.message = data.message
				})
 
			},

			async edit_memo(){


				if(!this.subject){

				Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Subject cannot be blank',
					  showConfirmButton: true
					  
					})
				return
				}



				if(!this.message){

				Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Message cannot be blank',
					  showConfirmButton: true
					  
					})
				return
				}


				this.text = 'Please wait...'
				this.disabled = true

				const res = await axios.post(this.$store.state.url+'api/edit-memo',{
					subject : this.subject,
					message : this.message,
					id : this.id 
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				Swal.fire({
					  position: "center",
					  icon: "success",
					  title: "Success",
					  text : 'Changes saved successfully',
					  showConfirmButton: true
					});

			this.text = 'Save changes'
			this.disabled = false 
			this.memo_details()

		
			}
		
		},

		created(){
			this.id = this.$route.params.id 
			this.memo_details()
		}

	}

</script>