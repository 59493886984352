<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3 class="color-3">Members</h3>
					</div>


					<div class="shadow rounded p-3">
						
						
						<div class="table-responsive">

							<table class="table table-hover">

								<thead>
									<tr>
										<th>M/Number</th>
										<th> Name </th>
										<th> Email </th>
										<th> Phone </th>
										<th> County </th>
										<th> Actions </th>
									</tr>
								</thead>


								<tbody>
									
									<tr v-for="m in members">
										<td> <router-link :to=" { name : 'member-details', params : { id : m.id } } "> {{m.membership_number}}</router-link> </td>
										<td> {{m.title}}, {{m.first_name}} {{m.last_name}} </td>
										<td> {{m.email}} </td>
										<td> {{m.phone}} </td>
										<td> {{m.county}} </td>
										<td> <router-link :to=" { name : 'member-details', params : { id : m.id } } " class="btn btn-outline-success"> <i class="bi bi-eye"></i> View</router-link> </td>
									</tr>

								</tbody>
								
							</table>
							
						</div>



					</div>

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			members : []		
			}
		},

		methods : {
			async get_members(){

				const res = await axios.get(this.$store.state.url+'api/get-members').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.members = res  
				
			}
		
		},

		created(){

			this.get_members()

		}

	}

</script>