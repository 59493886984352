<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3 class="color-3">Member details</h3>
					</div>


					<div class="shadow rounded p-3">
						
						
						<div class="row">
							<div class="col-md-6">
								<img v-if="photo!='na'" :src="this.$store.state.url+'assets/profile-photos/'+photo" class="profile-photo">
							</div>

							<div class="col-md-6" style="text-align:right;">

								<button :disabled="disabled3" v-if="status=='Active'" @click="deactivate_member()" class="btn btn-2" style="margin-right:5px;"> <i class="bi bi-x"></i> {{text3}}</button>


								<button :disabled="disabled2" v-if="status=='Inactive'" @click="activate_member()" class="btn btn-2" style="margin-right:5px;"> <i class="bi bi-check"></i> {{text2}} </button>
								
								<button @click="delete_member()" class="btn btn-danger"> <i class="bi bi-x"></i> Delete</button>

							</div>

						</div>


						<div class="row">

							<div class="col-md-6">
							<h6 class="mt-2">Membership number : {{membership_number}} </h6>
							<h6 class="mt-2">Package : {{package_name}} </h6>	
							</div>

							<div class="col-md-6" style="text-align:right;">
								Status : <span class="badge bg-info">{{status}}</span>, Expires : <span class="badge bg-danger">{{expires_on}}</span>
							</div>
							
						</div>

						


						<div class="table-responsive">

							<table class="table">
								
								<thead>
									<tr>
										<th> Name </th>
										<th> Email </th>
										<th> Phone </th>
										<th> County </th>
										<th> Address </th>
									</tr>
								</thead>

								<tbody>
									
									<tr>
										<td> {{name}} </td>
										<td> {{email}} </td>
										<td> {{phone}} </td>
										<td> {{county}} </td>
										<td> {{address}} </td>
									</tr>

								</tbody>

							</table>
							
						</div>



					<div style="padding-top:30px;">

						<h5>Edit member</h5>
						
						<form @submit.prevent="edit_member()">
							
							<div class="row">
								<div class="col-md-6 mt-2">
									<label>Expires on:</label>
									<input type="text" class="form-control" v-model="expires_on" disabled>
								</div>

								<div class="col-md-6 mt-2">
									<label>Password:</label>
									<input type="password" class="form-control" v-model="password">
								</div>

								<div class="col-md-6 mt-2">
									<button class="btn btn-1"> {{text}} </button>
								</div>


							</div>

						</form>

					</div>

					</div>




					<div style="padding-top:40px;">
						<h3 class="color-3">Upgrade/downgrade member</h3>
					</div>

					<div class="shadow rounded p-3" style="margin-top: 20px;">


						<div class="row">

						<div class="col-md-3">
							<h6>Current subscription : </h6>
							<h6> <strong> {{package_name}} </strong> </h6>
							
						</div>

						<div class="col-md-3">
							<label>Downgrade/upgrade to : </label>
							<select class="form-select" v-model="new_package" @change="package_details_2()">
								<option :disabled="p.name==package_name" v-for="p in packages" :value="p.id"> {{p.name}} - Ksh.{{p.cost}}/Year </option>
							</select>
							<p v-if="cost" class="mt-2">
								Subscription fee : <strong> Ksh.{{cost}} </strong>
							</p>
						</div>

						<div class="col-md-3" style="padding-top:18px;">
							<button :disabled="disabled4" @click="change_package()" class="btn btn-1 w-100">{{text4}}</button>
						</div>
						
					</div>	

					</div>










					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			id : '',
			details : [],
			photo : '',
			membership_number : '',
			status : '',
			expires_on : '',
			password : '',
			text : 'Save changes',
			disabled : false,
			package : '',
			package_name : '',
			packages : [],
			cost : '',
			new_package : '',
			text2 : 'Activate',
			disabled2 : false,
			text3 : 'Deactivate',
			disabled3 : false,
			text4 : 'Subscribe',
			disabled4 : false		
			}
		},

		methods : {

			async change_package(){


				if(!this.new_package){
					Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Select a package',
					  showConfirmButton: true,
					  
					})
					return
				}

				
				this.disabled4 = true
				this.text4 = 'Please Wait...'
				const res = await axios.post(this.$store.state.url+'api/change-package',{
					id : this.id,
					new_package : this.new_package,
					membership_number : this.membership_number,
					old_package : this.package
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.member_details()
				Swal.fire({
					  position: "center",
					  icon: "success",
					  title: "Success",
					  text : 'Changes saved successfully',
					  showConfirmButton: true,
					  
					})

				this.disabled4 = false
				this.text4 = 'Subscribe'

				
			},

			async get_packages(){
				const res = await axios.get(this.$store.state.url+'api/get-packages').then(function(response){
					return response.data 
				}).catch(function(error){
					console.log(error)
				})

				this.packages = res 
			},



			async delete_member(){
				const res = await axios.post(this.$store.state.url+'api/delete-member',{
					id : this.id,
					membership_number : this.membership_number
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.$router.push('/members')
		
			},

			async activate_member(){
				this.disabled2 = true
				this.text2 = 'Please wait..'
				const res = await axios.post(this.$store.state.url+'api/activate-member',{
					id : this.id
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				Swal.fire({
					  position: "top-center",
					  icon: "success",
					  title: "Changes saved successfully",
					  showConfirmButton: false,
					  timer: 1500
					})

				this.member_details()

				this.disabled2 = false
				this.text2 = 'Activate'

				
			},

			async deactivate_member(){
				this.disabled3 = true
				this.text3 = 'Please wait..'
				const res = await axios.post(this.$store.state.url+'api/deactivate-member',{
					id : this.id
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				Swal.fire({
					  position: "top-center",
					  icon: "success",
					  title: "Changes saved successfully",
					  showConfirmButton: false,
					  timer: 1500
					})

				this.member_details()

				this.disabled3 = false
				this.text3 = 'Deactivate'

				
			},


			async edit_member(){

				if(!this.expires_on){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Error",
					  text : 'Expiry date cannot be blank',
					  showConfirmButton: true,
					  
					});
					return
				}

				const res = await axios.post(this.$store.state.url+'api/edit-member',{
					id : this.id,
					expires_on : this.expires_on,
					password : this.password
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				Swal.fire({
					  position: "top-center",
					  icon: "success",
					  title: "Changes saved successfully",
					  showConfirmButton: false,
					  timer: 1500
					})

				this.member_details()


				
			},


			async member_details(){

				const res = await axios.get(this.$store.state.url+'api/member-details/'+this.id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.details = res
				res.forEach((data)=>{
					this.photo = data.photo
					this.membership_number = data.membership_number
					this.name = data.title+', '+data.first_name+' '+data.last_name
					this.email = data.email
					this.phone = data.phone
					this.county = data.county
					this.address = data.address
					this.status = data.status
					this.expires_on = data.expires_on
					this.package = data.package
				})


				this.package_details()

				
			},


			async package_details(){
				const res = await axios.get(this.$store.state.url+'api/package-details/'+this.package).then(function(response){
					return response.data 
				}).catch(function(error){
					console.log(error)
				})

				res.forEach((data)=>{
					this.package_name = data.name 

				})

			},

			async package_details_2(){
				const res = await axios.get(this.$store.state.url+'api/package-details/'+this.new_package).then(function(response){
					return response.data 
				}).catch(function(error){
					console.log(error)
				})

				res.forEach((data)=>{
					this.cost = data.cost 
					
				})

			}


		
		},

		created(){

			this.id  = this.$route.params.id
			this.member_details()
			this.get_packages()

		}

	}

</script>